/** style.css **/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
html,
body {
	height: 100%;
}
body {
	margin: 0;
	border-top: 5px solid #070d24;
	background: url(../images/BGS/default.jpg) #000000 no-repeat center top;
	background-attachment: fixed;
	background-size: cover;
	color: #6d7c9b;
}
/*Transitions START*/
.headerUtlsLinks a,
.restorePasswordHandler,
.globalButton,
nav a,
nav li,
input,
.hitArea,
nav a.sellTickets span,
.featuredEventsCarouselNavUnit,
.featuredEventsCarouselNavUnitEdge,
.unitWithImage,
.unitFocused,
.imagesBlocks a,
.copy a,
.eventsList td,
.eventsList tr,
.ng-table-pager ul li a,
.eventsListFilter,
.navSubCategoriesGroups a,
.contactBar h2,
.featuredEventsRule,
.titleSocials a,
.loggedInMenuDrop,
.accountRow,
.accountRowControls p,
.globalPopupCore,
svg {
	transition: all ease-out 200ms;
	-webkit-transition: all ease-out 200ms;
}
/*##### 500 MS*/
.overlay,
.loginPanel,
.headerSearch {
	transition: all ease-out 500ms;
	-webkit-transition: all ease-out 500ms;
}
/*Transitions END*/
/*Globals START*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
li,
a,
input,
textarea,
select,
button {
	font-family: 'Roboto', sans-serif;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input,
textarea,
select {
	color: #909fbf;
	border-radius: 0;
}
input:disabled,
textarea:disabled,
select:disabled {
	background-color: rgb(235, 235, 228);
}
input,
textarea {
	-webkit-appearance: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}
h1,
h2 {
	font-size: 24px;
	line-height: 19px;
	font-weight: normal;
	text-transform: uppercase;
	margin: 0 0 30px 0;
}
h1 a {
	text-decoration: none;
}
body:not(.isMobile) h1 a:hover {
	text-decoration: underline;
}
h2 {
	font-size: 20px;
	line-height: 15px;
}
p {
	font-size: 16px;
}
a {
	color: #6d7c9b;
}
a:hover {
	color: #214387;
}
button {
	cursor: pointer;
}
* {
	box-sizing: border-box;
	outline: none !important;
}
*:focus,
*:active {
	outline: none !important;
}
.mainDiv {
	width: 1200px;
	margin: 0 auto;
}
.horzLine {
	float: left;
	width: 100%;
	height: 1px;
	background-color: #e9edf4;
}
.verticalLine {
	display: inline-block;
	height: 100%;
	width: 1px;
	background-color: #e9edf4;
}
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.9);
	opacity: 0;
}
.hitArea {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.featuredEventsCarouselFeaturedUnitImage img,
.featuredEventsCarouselNavUnitFocusImage img,
.unitWithImageImg img,
.unitFocusedImg img {
	float: left;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.relativeThis {
	position: relative;
}
/*****Global Popup Styles START*/
.globalPopup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
}
.globalPopupCore {
	position: absolute;
	left: 50%;
	top: 50%;
	padding: 20px;
	transform: translateX(-50%) translateY(-100%);
	-webkit-transform: translateX(-50%) translateY(-100%);
	min-width: 200px;
	min-height: 200px;
	background-color: #ffffff;
}
.globalPopupCore .contentsTitle h1, .globalPopupCore .contentsCore p {
	margin: 0;
}
.globalPopupCore .contentsCore {
	margin-top: 30px;
}
.globalPopup[size='medium'] .globalPopupCore {
	width: 40vw;
}
.globalPopup.active {
	transform: translateY(0);
	-webkit-transform: translateY(0);
}
.globalPopup.active .overlay {
	opacity: 1;
}
.globalPopup.active .globalPopupCore {
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
}
.globalPopup.spread .globalPopupCore {
	width: 100vw!important;
	height: 100vh!important;
}
.globalPopupControls {
	float: right;
}
.globalPopupControls a {
	float: left;
	margin-left: 15px;
}
.globalPopupControls a svg {
	float: left;
	width: 40px;
	height: 40px;
	fill: #6d7c9b;
}
.globalPopupControls a.print svg {
	width: 30px;
}
.globalPopupControls a:hover svg {
	fill: #214387;
}

/*****Global Popup Styles END*/

/*****Global Table Like Styles START*/
.tableLike {
	width: 100%;
	display: table;
}
.rowLike {
	width: 100%;
	display: table-row;
}
.rowLikeHead p {
	font-weight: 500;
}
.cellLike {
	display: table-cell;
	border-bottom: solid 1px #e9edf4;
	padding: 16px 0;
	width: 60%;
}
.cellLike p,
.cellLike h1 {
	margin: 0;
}
.cellLike.cols3 {
	column-span: 3;
}
.cellLike.otherSide {
	text-align: right;
	width: 15%;
}
.cellLike.center {
	text-align: center;
	width: 10%;
}
.tableLike.noLastBorder .rowLike:last-child .cellLike,
.tableLike.noBorders .cellLike {
	border-bottom: none;
}
.tableLike.condensed .cellLike {
	padding: 10px 0;
}

/*****Global Table Like Styles START*/

/*****Global Form Styles START*/
.globalForm {
	width: 100%;
	display: inline-block;
	text-align: left;
	vertical-align: top;
}
.globalFormTwoUnits {
	float: left;
	width: 100%;
}
.globalFormUnit {
	float: left;
	width: 100%;
	margin-bottom: 30px;
}
/*****#####Two Form Units per Row START*/
.globalFormTwoUnits .globalFormUnit {
	width: calc(50% - 10px);
}
.globalFormTwoUnits .globalFormUnit:first-child {
	margin-right: 20px;
}
/*****#####Two Form Units per Row END*/
/*****#####Three Form Units per Row START*/
.globalFormThreeUnits .globalFormUnit {
	width: calc(33% - 10px);
}
.globalFormThreeUnits .globalFormUnit:not(:last-child) {
	margin-right: 20px;
}
/*****#####Two Form Units per Row END*/
.globalFormUnit.condensed {
	margin-bottom: 20px;
}
.globalFormUnit.balanced {
	margin: 12px 0;
}
.globalFormUnit p {
	font-size: 14px;
	margin: 0 0 10px 0;
}
.globalFormThreeUnits .globalFormUnit p {
	float: left;
}
.globalFormUnit
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.globalFormUnit select,
.globalFormUnit textarea {
	width: 100%;
	height: 40px;
	border: solid 1px #dadee6;
	padding: 0 10px;
	font-weight: 300;
}

.globalFormUnit input[type=tel]{
	padding-left: 50px !important
}

.globalFormUnit textarea {
	padding: 10px;
}
.globalFormUnit.textareaForFive textarea {
	height: 390px;
}
.globalFormUnit.textareaForTwo textarea {
	height: 100px;
}
.globalFormUnit.sidedButton
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.globalFormUnit.sidedButton select {
	width: 250px;
}
.globalFormUnit.extraInput
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.globalFormUnit.extraInput select {
	height: 65px;
	font-weight: 400;
	text-align: center;
	font-size: 30px;
}
.globalFormUnit.sidedButton.extraWidth
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.globalFormUnit.sidedButton.extraWidth select {
	width: 300px;
}
.globalFormUnit.sidedButton input,
.globalFormUnit.sidedButton select,
.globalFormUnit.sidedButton .globalButton {
	float: left;
}
.globalFormUnit
	input:not([type='checkbox']):not([type='radio']):not([type='submit']):focus,
.globalFormUnit select:focus {
	border: solid 1px #a8b4c8;
}

.globalFormUnit.dateDropDowns select {
	margin-right: -1px;
}
.globalFormUnit.dateDropDowns select.dayDropDown,
.globalFormUnit.dateDropDowns select.monthDropDown {
	width: 71px;
}
.globalFormUnit.dateDropDowns select.yearDropDown {
	width: 110px;
}

.globalFormUnit.dateDropDownsMonthAndYear select.monthDropDown {
	width: 100px;
	margin-right: 20px;
}
.globalFormUnit.dateDropDownsMonthAndYear select.yearDropDown {
	width: 130px;
}
/*****#####Form Fields Errors START*/
.globalFormUnit.error span {
	padding-top: 3px;
	/*display: none;*/
	float: right;
	font-size: 11px;
	color: #ea2525;
	font-weight: 500;
}
.globalFormUnit.error
	input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.globalFormUnit.error select {
	border: solid 1px #ea2525;
}
.globalFormUnit.error span {
	display: block;
}
/*****#####Form Fields Errors END*/
.globalFormUnitCheck {
	float: left;
	position: relative;
}
.globalFormUnitCheck input[type='checkbox'],
.globalFormUnitCheck input[type='radio'] {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	left: 0;
}
.globalFormUnitCheck input[type='checkbox'],
.globalFormUnitCheck input[type='radio'] {
	z-index: 2;
	margin: 0;
	opacity: 0;
}
.globalFormUnitCheckCore {
	float: left;
	width: 27px;
	height: 27px;
	border: solid 1px #dadee6;
}
.globalFormUnitRadio .globalFormUnitCheckCore {
	border-radius: 100%;
}
.globalFormUnitCheck p {
	float: left;
	margin: 3px 0 0 10px;
}
.globalFormUnitCheckCore svg {
	fill: #358138;
	opacity: 0;
}
:checked + div svg,
.globalFormUnitCheck input[type='radio']:checked + div svg {
	opacity: 1;
}
body:not(.isMobile)
	.globalFormUnitCheck:hover:not(.globalFormUnitCheckMini)
	svg,
body:not(.isMobile)
	.globalFormUnitCheck.globalFormUnitCheckMini
	input[type='checkbox']:not(:checked):hover
	+ div
	svg {
	opacity: 0.3;
}
.globalFormUnitCheck.globalFormUnitCheckMini input[type='checkbox'] {
	width: 27px;
}
.globalFormUnitCheck.big .globalFormUnitCheckCore {
	width: 40px;
	height: 40px;
}
/*****Global Form END*/
/*****Global Buttons START*/
.globalButtonsBar {
	float: left;
	text-align: center;
	width: 100%;
}
.globalButtonsBar.otherSide {
	float: right;
	width: auto;
}
.globalButtonsBar.edged .globalButton {
	float: left;
	margin-right: 8px;
}
.globalButtonsBar.edged .globalButton:last-child,
.globalButtonsBar.single .globalButton {
	margin-right: 0;
}
.globalButtonsBar.vertical .globalButton {
	margin-bottom: 8px;
}
.globalButtonsBar.vertical .globalButton:last-child {
	margin-bottom: 0;
}
.globalButtonsBar.topSpace {
	margin-top: 40px;
}
.globalButtonsBar.bottomSpace {
	margin-bottom: 40px;
}
.globalButtonsBar.midTopSpace {
	margin-top: 20px;
}
.globalButtonsBar.tinyTopSpace {
	margin-top: 12px;
}
.globalButtonsBar.horzSpace {
	margin-right: 10px;
}
.globalButton {
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	border: none;
	padding: 0;
	cursor: pointer;
	text-decoration: none;
}
.globalButton[disabled],
.globalButton[disabled]:hover {
	background-color: #e0dddd !important;
	cursor: default !important;
	opacity: 1 !important;
}
.globalButton.otherSide {
	float: right;
}
.globalButton.callToAction {
	font-size: 30px;
	line-height: 25px;
	font-weight: 500;
	padding: 20px 30px;
	color: #ffffff;
}
.globalButton.callToAction:hover,
.globalButton.inputSidedButton:hover {
	opacity: 0.8;
}
.globalButton.callToAction span {
	display: block;
	font-size: 16px;
	line-height: 14px;
	font-weight: 300;
	margin-top: 10px;
}
.globalButton.normalChars {
	text-transform: none;
}
.globalButton.regularFont {
	font-weight: normal;
}
.globalButton.fullWidth {
	width: 100%;
}
.globalButton.callToAction.blue,
.globalButton[color='blue'] {
	background-color: #2d5cba;
}
.globalButton.callToAction.facebookBlue {
	background-color: #3b5998;
}
.globalButton.callToAction.twitterBlue {
	background-color: #00aced;
}
.globalButton.callToAction.googleRed {
	background-color: #dd4b39;
}
.globalButton.callToAction.green,
.globalButton[color='green'] {
	background-color: #358138;
}
.globalButton.callToAction.nevy,
.globalButton[color='nevy'] {
	background-color: #13235f;
}
.globalButton.callToAction.red,
.globalButton[color='red'] {
	background-color: #b14c4c;
}
.globalButton svg {
	float: left;
	width: 30px;
	height: 30px;
	margin: -2.5px;
	fill: #ffffff;
}
.globalButton:not(.fullWidth):not(.iconButton) svg {
	margin-right: 20px;
}
.globalButton.borderedButton {
	font-size: 14px;
	line-height: 11px;
	padding: 10px 0;
	width: 104px;
	background-color: #ffffff;
}
.globalButton.borderedButton.autoWidth {
	width: auto;
	padding: 10px;
}
.globalButton.borderedButton.iconButton svg {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: -5px 5px -5px -5px;
	fill: #6d7c9b;
}
body:not(.isMobile) .globalButton.borderedButton.iconButton:hover svg {
	fill: #ffffff;
}
.globalButton.loading svg {
	animation: spinner 1s infinite linear;
	-webkit-animation: spinner 1s infinite linear;
}
@keyframes spinner {
	from {
		transform: rotateZ(0);
	}
	to {
		transform: rotateZ(360deg);
	}
}
@-webkit-keyframes spinner {
	from {
		-webkit-transform: rotateZ(0);
	}
	to {
		-webkit-transform: rotateZ(360deg);
	}
}
.globalButtonsBar.extraWidth .globalButton.borderedButton {
	width: 151px;
}
.globalButton.borderedButton[color='grey'] {
	border: solid 1px #6d7c9b;
	color: #6d7c9b;
}
body:not(.isMobile) .globalButton.borderedButton[color='grey']:hover {
	border: solid 1px #2d5cba;
	background-color: #2d5cba;
}
.globalButton.borderedButton[color='lightGrey'] {
	border: solid 1px #dadee6;
	color: #6d7c9b;
}
body:not(.isMobile) .globalButton.borderedButton[color='lightGrey']:hover {
	border: solid 1px #2d5cba;
	background-color: #2d5cba;
}
.globalButton.borderedButton[color='nevy'] {
	border: solid 1px #13235f;
	color: #13235f;
}
body:not(.isMobile) .globalButton.borderedButton[color='nevy']:hover {
	border: solid 1px #13235f;
	background-color: #13235f;
}
.globalButton.borderedButton[color='red'] {
	border: solid 1px #b14c4c;
	color: #b14c4c;
}
body:not(.isMobile) .globalButton.borderedButton[color='red']:hover {
	border: solid 1px #b14c4c;
	background-color: #b14c4c;
}
.globalButton.borderedButton[color='green'] {
	border: solid 1px #358138;
	color: #358138;
}
body:not(.isMobile) .globalButton.borderedButton[color='green']:hover {
	border: solid 1px #358138;
	background-color: #358138;
}
body:not(.isMobile) .globalButton.borderedButton:hover {
	color: #ffffff;
}
.globalButton.inputSidedButton {
	padding: 12px 11px;
	color: #ffffff;
	font-size: 13.3333px;
}
/*****Global Buttons Styles END*/
a.miniLink {
	font-size: 14px;
	text-transform: none;
}
/*****Global Layout Styles START*/
.layoutHolder {
	float: left;
	width: 100%;
}
.layoutUnit {
	float: left;
}
.contentsBorderedRight .contentsCore,
.borderedOtherSide {
	border-right: solid 1px #e9edf4;
}
.borderedStartWith {
	border-left: solid 1px #e9edf4;
}
.borderedEndtWith {
	border-right: solid 1px #e9edf4;
}
.layoutThird {
	width: calc(33.333333% - 40px / 3);
	margin-right: 20px;
}
.layoutThird:nth-child(3n + 3) {
	margin-right: 0;
}
.layoutThird:nth-child(3n + 3) .contentsCore {
	border-right: 0;
}
/*****Global Layout Styles END*/
/*****Global Tooltip Styles START*/
[tooltip] {
	position: relative;
}
[tooltip]:hover {
	z-index: 99999;
}
[tooltip]:after {
	content: attr(tooltip);
	position: absolute;
	background-color: rgba(0, 0, 0, 0.8);
	color: #ffffff;
	white-space: nowrap;
	left: 50%;
	top: -30px;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	font-size: 12px;
	text-transform: none !important;
	padding: 4px 10px;
	display: none;
}
[tooltip]:hover:after {
	display: block;
}
[tooltip][tooltipPosition='left']:after {
	left: 0;
	transform: none;
	-webkit-transform: none;
}
[tooltip][tooltipPosition='right']:after {
	left: auto;
	right: 0;
	transform: none;
	-webkit-transform: none;
}
/*****Global Tooltip Styles END*/

.borderedBottom {
	border-bottom: solid 1px #e9edf4;
}
.borderedBottom:not(.contentsTitle):last-child {
	border-bottom: none;
}
/*Globals END*/
/*Header Styles START*/
header {
	float: left;
	position: relative;
	width: 100%;
	padding: 25px 20px;
}
body:not(.sideMenuActive) header {
	z-index: 2;
}
a.logo {
	float: left;
}
.headerSearch {
	height: 50px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	margin-top: 15px;
}
.ticketCutEdgeVertical {
	float: left;
	background: url(../images/ticketEdge.png) repeat-y right top;
	width: 3px;
	height: 100%;
}
.ticketCutEdgeVertical.flipped {
	transform: rotateZ(180deg);
	-webkit-transform: rotateZ(180deg);
	background-position: right bottom;
}
.headerSearchField {
	float: left;
	height: 100%;
	width: 500px;
}
.headerSearchField input[type='text'] {
	float: left;
	border: none;
	height: 100%;
	width: 100%;
	padding: 0 10px;
	font-size: 18px;
	font-weight: 300;
}
.headerSearch button {
	float: left;
	background: #ffffff;
	border: none;
	height: 100%;
	padding: 0 8px;
	margin: 0;
}
.headerSearch svg {
	float: left;
	width: 40px;
	height: 40px;
	fill: #9ea2ae;
}
.headerSearch button:hover svg {
	fill: #214387;
}
.headerUtlsLinks {
	float: right;
	margin-top: 20px;
}
.headerUtlsLinks a {
	color: #ffffff;
	float: left;
	font-size: 16px;
	padding: 10px;
}
.headerUtlsLinks a * {
	float: left;
}
.headerUtlsLinks a svg {
	fill: #ffffff;
	width: 25px;
	height: 25px;
	margin-right: 10px;
}
.headerUtlsLinks a.help svg {
	margin-right: 0;
	margin-top: 4px;
	height: 21px;
	width: 21px;
}
.headerUtlsLinks a svg.arrow {
	width: 20px;
	height: 20px;
	margin: 4px 0 0 0;
}
.headerUtlsLinks a span {
	margin-top: 2px;
	font-size: 16px;
	max-width: 121px;
	overflow: hidden;
}
body:not(.isMobile) .headerUtlsLinks a:hover,
.loggedInMenu.active > a {
	background-color: #ffffff;
	color: #214387;
}
body:not(.isMobile) .headerUtlsLinks a:hover svg,
.loggedInMenu.active > a svg {
	fill: #214387;
}
/*****Auto Complete Styles START*/
.angucomplete-holder {
	float: left;
	width: 100%;
	height: 100%;
}
.angucomplete-dropdown {
	width: 100% !important;
	top: 100%;
	margin-top: 0 !important;
}
.angucomplete-row {
	color: #7c84a5;
}
.angucomplete-selected-row {
	background-color: #1b3975;
	color: #ffffff;
}
.angucomplete-description {
	font-size: 12px;
	color: #c5c5c5;
}
/*****Auto Complete Styles END*/
/*****Login Panel Styles START*/

.loginView {
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 0;
	left: 0;
	top: 0;
	height: 0;
	overflow: hidden;
}
.loginPanelHolder {
	width: 100%;
	transform: translateY(90px);
	-webkit-transform: translateY(90px);
	perspective: 2000px;
	-webkit-perspective: 2000px;
}

.loginPanel {
	margin-left: 50%;
	width: 1160px;
	background-color: #ffffff;
	padding: 80px 120px;
	text-align: center;
	transform: rotateX(-100deg) translateX(-50%);
	-webkit-transform: rotateX(-100deg) translateX(-50%);
	transform-origin: top left;
	-webkit-transform-origin: top left;
	transform-style: preserve-3d;
	-webkit-ransform-style: preserve-3d;
	opacity: 0;
}
.loginPanelHalf {
	width: 458px;
	float: left;
	padding: 0 50px 0 0;
}
.loginPanelHalf.bordered {
	border-left: solid 1px #e9edf4;
	padding: 0 0 0 50px;
}
.restorePasswordHandler {
	float: right;
	font-size: 14px;
}
.loginOr {
	float: left;
	width: 100%;
	text-align: center;
	position: relative;
	padding: 30px 0;
}
.loginOr h1 {
	font-size: 30px;
	line-height: 22px;
	display: inline-block;
	color: #ced5e3;
	margin: 0;
	position: relative;
	z-index: 2;
	background-color: #ffffff;
	padding: 0 10px;
}
.loginOr .horzLine {
	position: absolute;
	left: 0;
	top: 50%;
}
/*.globalButtonsBar.newAccountSwitcher{
  display: none;
}*/

body.loginOpened header {
	position: fixed;
	width: 1200px;
	z-index: 1;
}
body.loginOpened header .headerSearch {
	opacity: 0;
}
body.loginOpened nav {
	margin-top: 134px;
}
body.loginOpened .logo {
	position: relative;
	z-index: 3;
}
body.loginOpened .loginHandler {
	position: relative;
	z-index: 3;
	background-color: #ffffff;
	color: #6d7c9b;
}
body.loginOpened .loginHandler svg {
	fill: #6d7c9b;
}
body.loginOpened .loginHandler svg.arrow,
.loggedInMenu.active > a svg.arrow {
	transform: rotateZ(180deg);
	-webkit-transform: rotateZ(180deg);
}
body.loginOpened .loginView {
	height: 100%;
	opacity: 1;
}
body.loginOpened .loginPanel {
	transform: rotateX(0deg) translateX(-50%);
	-webkit-transform: rotateX(0deg) translateX(-50%);
	opacity: 1;
}
body.loginOpened .overlay {
	opacity: 1;
}
/*****Login Panel Styles END*/
/*****Logged In Menu Styles START*/
.loggedInMenu {
	float: left;
	position: relative;
}
.loggedInMenu.active > a {
	position: relative;
	z-index: 21;
}
.loggedInMenuDrop {
	position: absolute;
	display: none;
	z-index: 20;
	min-width: 209px;
	top: 45px;
	right: 0;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.loggedInMenu.active .loggedInMenuDrop {
	display: block;
}
.loggedInMenuDrop a {
	float: left;
	width: 100%;
	font-size: 15px;
	text-decoration: none;
	border-bottom: solid 1px #e9edf4;
	white-space: nowrap;
	color: #6d7c9b;
	padding: 15px;
}
.loggedInMenuDrop a svg,
.loggedInMenuDrop a span {
	float: left;
}
.loggedInMenuDrop a svg {
	width: 24px;
	height: 24px;
	fill: #6d7c9b;
}
.loggedInMenuDrop a:hover {
	background-color: #e9edf4;
}
/*****Logged In Menu Styles END*/
/*Header Styles END*/
/*Nav Styles START*/
nav {
	float: left;
	width: 100%;
	position: relative;
	background-color: #13235f;
	border-bottom: solid 7px #214387;
}
nav ul {
	float: left;
	margin: 0;
	padding: 0;
	list-style-type: none;
}
nav > ul > li {
	float: left;
}
nav > ul > li > a {
	float: left;
	padding: 30px;
	border-right: solid 1px #213068;
	text-decoration: none;
	font-size: 16px;
	line-height: 13px;
	text-transform: uppercase;
}
nav li[category='music'] > a,
nav li[category='music'] .navSubCategories a:hover,
[theme='music'] .navSubCategories .navSubCategoriesGroups a:not(.active):hover {
	color: #edc5c5;
}
nav li[category='festivals'] > a,
nav li[category='festivals'] .navSubCategories a:hover,
[theme='festivals']
	.navSubCategories
	.navSubCategoriesGroups
	a:not(.active):hover {
	color: #da8fe8;
}
nav li[category='sports'] > a,
nav li[category='sports'] .navSubCategories a:hover,
[theme='sports']
	.navSubCategories
	.navSubCategoriesGroups
	a:not(.active):hover {
	color: #abc99d;
}
nav li[category='theater'] > a,
nav li[category='theater'] .navSubCategories a:hover,
[theme='theater']
	.navSubCategories
	.navSubCategoriesGroups
	a:not(.active):hover {
	color: #8ce9ef;
}
nav li[category='comedy'] > a,
nav li[category='comedy'] .navSubCategories a:hover,
[theme='comedy']
	.navSubCategories
	.navSubCategoriesGroups
	a:not(.active):hover {
	color: #e4e8a0;
}
body:not(.isMobile) nav > ul > li:hover {
	background-color: #214387;
}
.navSubCategories {
	float: left;
	position: relative;
	min-height: 320px;
	width: 1200px;
	margin-top: -7px;
	padding: 50px 30px;
	background: #214387 no-repeat right bottom;
}
nav ul li .navSubCategories {
	padding: 50px 30px;
	display: none;
	position: absolute;
	z-index: 3;
	top: 80px;
	left: 0;
	border-bottom: solid 3px #1b3975;
}
body:not(.isMobile) nav ul li:hover .navSubCategories {
	display: inline-block;
}
nav ul li[category='music'] .navSubCategories {
	background-image: url(../images/dropdowns/music.jpg);
}
nav ul li[category='festivals'] .navSubCategories {
	background-image: url(../images/dropdowns/festivals.jpg);
}
nav ul li[category='sports'] .navSubCategories {
	background-image: url(../images/dropdowns/sports.jpg);
}
nav ul li[category='theater'] .navSubCategories {
	background-image: url(../images/dropdowns/theater.jpg);
}
nav ul li[category='comedy'] .navSubCategories {
	background-image: url(../images/dropdowns/comedy.jpg);
}
.navSubCategoriesGroups {
	float: left;
}
.navSubCategoriesGroups h1 {
	color: #ffffff;
	font-size: 22px;
	text-transform: none;
	padding-left: 40px;
	position: relative;
}
.navSubCategoriesGroups h1 a {
	position: absolute;
	left: 0;
	transform: translateX(-15px) translateY(-10px);
	-webkit-transform: translateX(-15px) translateY(-10px);
}
.navSubCategoriesGroups h1 a:hover {
	background-color: rgba(255, 255, 255, 0.1);
}
.navSubCategoriesGroups h1 svg {
	float: left;
	width: 40px;
	height: 40px;
	fill: #ffffff;
}
.navSubCategoriesGroup {
	float: left;
	margin-right: 110px;
}
.navSubCategoriesGroups.subsView .navSubCategoriesGroup {
	margin: 0 75px 0 40px;
}
.navSubCategoriesGroup a {
	display: block;
	font-size: 18px;
	line-height: 15px;
	font-weight: 300;
	color: #ffffff;
	text-decoration: none;
	padding: 12px 0;
}
body:not(.isMobile) .navSubCategoriesGroup a:not(.active):hover {
	transform: translateX(-5px);
	-webkit-transform: translateX(-5px);
}
.navSubCategoriesGroup a.active {
	font-weight: 500;
	cursor: default;
}
.navSubCategoriesGroup a:first-child {
	padding-top: 0;
}
.navSubCategoriesGroup a:last-child {
	padding-bottom: 0;
}

nav a.sellTickets {
	width: 151px;
	height: 93px;
	position: absolute;
	right: 20px;
	bottom: 0;
	word-spacing: 500px;
	line-height: 24px;
	text-transform: uppercase;
	text-decoration: none;
}
nav a.sellTickets span {
	position: absolute;
	width: inherit;
	height: inherit;
	padding: 32px 0 0 60px;
	background: url(../images/sell.png) no-repeat left top;
	color: #ffffff;
}
nav a.sellTickets span.sellTicketsHover {
	opacity: 0;
	background-position: left bottom;
	color: #214387;
}
nav a.sellTickets:hover span.sellTicketsHover {
	opacity: 1;
}

nav .contentWhiteOverlay {
	position: fixed;
	width: 1200px;
	height: 100%;
	top: 220px;
	background-color: rgba(255, 255, 255, 0.8);
	display: none;
}
/*****Mobile Navigation*/
.mobileMenuHandler {
	position: absolute;
	left: 15px;
	bottom: 20px;
	width: 38px;
}
.mobileMenuHandler span {
	float: left;
	width: 100%;
	height: 6px;
	margin-bottom: 8px;
	background-color: #ffffff;
}
.mobileMenuHandler span:last-child {
	margin-bottom: 0;
}
/*Nav Styles END*/
/*Contents Styles START*/
.mainView {
	float: left;
	position: relative;
	width: 100%;
	background-color: #ffffff;
}
/*****FeaturedEventCarousel Styles START*/
.featuredEventsCarousel {
	float: left;
	width: 100%;
	padding: 3px 10px 10px 10px;
	background-color: #214387;
}
.featuredEventsCarousel.noResults {
	padding: 30px;
	text-align: center;
	background-color: #FCFCFC;
}
.featuredEventsCarousel.noResults img {
	width: 30%;
}
.featuredEventsCarousel.noResults h2 {
	margin-top: 25px;
	text-transform: initial;
}
.featuredEventsCarouselFeaturedUnits,
.featuredEventsCarouselFeaturedUnit,
.featuredEventsCarouselNav {
	float: left;
	width: 100%;
}
.featuredEventsCarouselFeaturedUnitImage {
	float: left;
	width: calc(100% - 370px);
	height: 387px;
}
.featuredEventsCarouselFeaturedUnitData {
	float: right;
	width: 350px;
	padding: 10px 10px 0 0;
	color: #ffffff;
}
.featuredEventsCarouselFeaturedUnitData h1 {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 20px;
	line-height: 25px;
}
.featuredEventsCarouselFeaturedUnitData h1 a {
	text-decoration: none;
	color: #ffffff;
}
.featuredEventsCarouselFeaturedUnitData h1 a:hover {
	text-decoration: underline;
}
.featuredEventsCarouselFeaturedUnitDataContents {
	float: left;
	width: 100%;
}
.featuredEventsCarouselFeaturedUnitVenue {
	float: left;
	margin-left: 30px;
	position: relative;
}
.featuredEventsCarouselFeaturedUnitVenue svg {
	float: left;
	width: 50px;
	height: 50px;
	margin: -8px 10px 0 0;
	fill: #ffffff;
}
.featuredEventsCarouselFeaturedUnitVenueName {
	float: left;
	max-width: 140px;
}
.featuredEventsCarouselFeaturedUnitVenue p {
	font-size: 16px;
	margin: 0;
}
body:not(.isMobile) .featuredEventsCarouselFeaturedUnitVenue .hitArea:hover {
	background-color: rgba(255, 255, 255, 0.1);
	transform: scale3d(1.1, 1.4, 1);
	-webkit-transform: scale3d(1.1, 1.4, 1);
}
.featuredEventsCarouselFeaturedUnitIntro {
	float: left;
	width: calc(100% - 10px);
	height: 120px;
	overflow: hidden;
	margin: 20px 0;
}
.featuredEventsCarouselFeaturedUnitIntro p {
	font-weight: 300;
	line-height: 24px;
	margin: 0;
	letter-spacing: 1px;
}
.featuredEventsCarouselFeaturedUnitData
	.globalButtonsBar:not(.edged)
	.globalButton {
	margin: 0 5px;
}
/*#####Carousel NAV*/
.featuredEventsCarouselNav {
	background-color: #2d5cba;
}
.featuredEventsCarouselNavUnit {
	float: left;
	position: relative;
	width: 240px;
	padding: 10px;
	cursor: pointer;
}
.featuredEventsCarouselNavUnit:not(.slick-current):hover {
	transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
}
.featuredEventsCarouselNavUnitFocus {
	float: left;
	width: 100%;
	padding: 10px 5px 10px 10px;
	background-color: #ffffff;
}
.featuredEventsCarouselNavUnitFocusImage {
	float: left;
	width: 100px;
	height: 100px;
	margin-right: 5px;
}
.featuredEventsCarouselNavUnitFocusCore {
	float: left;
	width: calc(100% - 103px - 5px);
	margin-top: 10px;
}
.featuredEventsCarouselNavUnitFocusCore .dateDayFocus,
.featuredEventsCarouselNavUnitFocusCoreSeats {
	width: 100%;
}
.featuredEventsCarouselNavUnitFocusCoreSeats {
	float: left;
	border-top: solid 1px #e9edf4;
	margin-top: 15px;
}
.featuredEventsCarouselNavUnitFocusCoreSeats p {
	margin: 15px 0 0 0;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
}
.featuredEventsCarouselNavUnitFocusCoreSeats p span {
	font-size: 20px;
	font-weight: 500;
}
.featuredEventsCarouselNavUnitDetails,
.featuredEventsCarouselNavUnitDetailsBordered {
	float: left;
	width: 100%;
}
.featuredEventsCarouselNavUnitDetailsBordered {
	background: url(../images/carouselNavBorder.png) repeat-x top left;
	padding: 10px;
}
.featuredEventsCarouselNavUnitDetails h1 {
	font-size: 15px;
	line-height: 18px;
	margin: 0;
	font-weight: 500;
	color: #4a5978;
	height: 36px;
	overflow: hidden;
}
.featuredEventsCarouselNavUnitDetails p {
	font-size: 15px;
	line-height: 12px;
	margin: 5px 0 0 0;
	line-height: 22px;
	height: 22px;
	overflow: hidden;
}
.featuredEventsCarouselNavUnit .featuredEventsCarouselNavUnitDetails {
	background-color: #ffffff;
}
.featuredEventsCarouselNavUnit[category='music']
	.featuredEventsCarouselNavUnitDetails,
[category='music'] .unitWithImage.back {
	background-color: #f8e9e9;
}
.featuredEventsCarouselNavUnit[category='festivals']
	.featuredEventsCarouselNavUnitDetails,
[category='festivals'] .unitWithImage.back {
	background-color: #fbe7ff;
}
.featuredEventsCarouselNavUnit[category='sports']
	.featuredEventsCarouselNavUnitDetails,
[category='sports'] .unitWithImage.back {
	background-color: #eaf2e6;
}
.featuredEventsCarouselNavUnit[category='theater']
	.featuredEventsCarouselNavUnitDetails,
[category='theater'] .unitWithImage.back {
	background-color: #e0fdff;
}
.featuredEventsCarouselNavUnit[category='comedy']
	.featuredEventsCarouselNavUnitDetails,
[category='comedy'] .unitWithImage.back {
	background-color: #fcfde5;
}
.featuredEventsCarouselNavUnitEdge {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 2px;
	height: calc(100% - 20px);
	background: url(../images/ticketEdgeCarousel.png) repeat-y;
}
.featuredEventsCarouselNavUnitEdgeEnd {
	background-position: left bottom;
	left: auto;
	right: 10px;
	transform: rotateZ(180deg);
	-webkit-transform: rotateZ(180deg);
}
.featuredEventsCarouselNavUnit.slick-current {
	background-color: #214387;
	padding-top: 0;
}
.featuredEventsCarouselNavUnit.slick-current
	.featuredEventsCarouselNavUnitEdge {
	top: 0;
	height: calc(100% - 10px);
}
/*****FeaturedEventCarousel Styles END*/
/*****Dates Styles START*/
.dateDayFocus {
	float: left;
	color: #214387;
}
.dateDayFocus p {
	margin: 0;
	font-weight: 500;
}
.dateDayFocus .dateDayFocusTime {
	font-size: 13px;
	font-weight: 300;
	clear: both;
}
.dateDayFocusDay,
.dateDayFocusRest {
	float: left;
}
.dateDayFocusDay {
	margin-right: 7px;
}
.dateDayFocusDay.alignMiddle {
	margin-top: 7px;
}
.dateDayFocusDay p {
	font-size: 40px;
	line-height: 30px;
}
.dateDayFocusRest p {
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 1px;
	margin: 0;
}
.dateDayFocusRest p.day {
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0;
}
/*#####Big Date*/
.dateDayFocus.big .dateDayFocusDay p {
	font-size: 50px;
	line-height: 40px;
}
.dateDayFocus.big .dateDayFocusRest p {
	font-size: 20px;
	line-height: 21px;
}
.dateDayFocus.big .dateDayFocusRest p.day {
	font-size: 16px;
	letter-spacing: 1px;
}
/*#####White Date*/
.dateDayFocus.white {
	color: #ffffff;
}
/*#####Date in Events List*/
.dateDayFocus.inEventsList {
	color: #6d7c9b;
	border-right: solid 1px #e9edf4;
	padding: 15px 30px 15px 0;
	min-width: 160px;
}
.dateDayFocus.inEventsList p {
	letter-spacing: 0;
}
.dateDayFocus.inEventsList .dateDayFocusDay p {
	font-size: 44px;
	font-weight: 300;
	color: #214387;
}
.dateDayFocus.inEventsList .dateDayFocusRest p {
	font-size: 14px;
	font-weight: 700;
}
.dateDayFocus.inEventsList .dateDayFocusRest p.day {
	font-size: 12px;
	font-weight: 700;
	color: #9ca6b9;
}
/*#####Date Range*/
.dateRange {
	float: left;
	width: 100%;
}
.dateRangeTo {
	float: left;
	margin: 0 15px;
}
.dateRangeTo svg {
	float: left;
	width: 40px;
	height: 40px;
	fill: #ffffff;
}
/*****Dates Styles END*/
.contents {
	float: left;
	width: calc(100% - 40px);
	padding: 50px 0;
	margin: 0 20px;
}
.contents.lessTop {
	padding-top: 20px;
}
.contentsTitle {
	float: left;
	width: 100%;
	position: relative;
}
.contentsTitle.withSide h1 {
	float: left;
	width: calc(100% - 160px);
	line-height: 30px;
}
.contentsTitle span {
	font-weight: 500;
	color: #214387;
}
.contentsTitle.withSideButton a {
	float: right;
	margin-top: -7px;
}
.contentsTitle.withSideButton a:not(.iconButton) svg {
	float: left;
	width: 40px;
	height: 40px;
	fill: #b7bdca;
}
.contentsTitle.withSideButton a:hover svg {
	fill: #214387;
}
.contentsTitle.topSpace {
	margin-top: 20px;
}
.contentsTitle.paymentMethods h1 {
	margin-bottom: 20px;
}
.contentsCore {
	float: left;
	width: 100%;
}
.contentsCore.topSpace {
	margin-top: 20px;
}
.contentsHalf {
	float: left;
	width: 50%;
}
.contentsHalf.startWithpadding {
	padding-left: 60px;
}
.contentsHalf.endWithPadding {
	padding-right: 60px;
}
.centeredContents {
	text-align: center;
}
.contentsCore.textData p {
	font-size: 18px;
	line-height: 30px;
	margin: 0 0 20px 0;
	word-wrap: break-word;
}
.contentsCore.textData p:last-child {
	margin-bottom: 0;
}
span.categoryTag {
	float: left;
	width: 7px;
	height: 19px;
	margin-right: 7px;
	background-color: #2d5cba;
}
/*****Categories Colors Styles START*/
div[category='music'] span.categoryTag,
[category='music'] .unitByDateDateBlock {
	background-color: #ce4f4f;
}
div[category='festivals'] span.categoryTag,
[category='festivals'] .unitByDateDateBlock {
	background-color: #8900a2;
}
div[category='sports'] span.categoryTag,
[category='sports'] .unitByDateDateBlock {
	background-color: #2e8c40;
}
div[category='theater'] span.categoryTag,
[category='theater'] .unitByDateDateBlock {
	background-color: #009eb1;
}
div[category='comedy'] span.categoryTag,
[category='comedy'] .unitByDateDateBlock {
	background-color: #998f4f;
}
/*****Categories Colors Styles END*/
/*****Unit With Image styles START*/
/*#####Flipper styles START*/
.flipperFlip {
	float: left;
	width: 100%;
	height: 100px;
	margin-bottom: 22px;
	perspective: 1000px;
}
.flipperFlip:last-child {
	margin-bottom: 0;
}
.flipper {
	background-color: #ffffff;
	transition: 0.6s;
	-webkit-transition: 0.6s;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	position: relative;
}
.flipperFlip:hover .front {
	transform: rotateX(180deg);
	-webkit-transform: rotateX(180deg);
}
.flipperFlip:hover .back {
	transform: rotateX(0);
	-webkit-transform: rotateX(0);
}
.front,
.back {
	height: 100px;
	width: 100%;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transition: 0.6s;
	-webkit-transition: 0.6s;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	position: absolute;
	top: 0;
	left: 0;
	background-color: #ffffff;
}
.unitWithImage.back {
	padding: 10px;
	transform: translateX(-10px) translateY(-10px) rotateX(-180deg);
	-webkit-transform: translateX(-10px) translateY(-10px) rotateX(-180deg);
}
.flipperFlip:hover .unitWithImage.back {
	transform: translateX(-10px) translateY(-10px) rotateX(0);
	-webkit-transform: translateX(-10px) translateY(-10px) rotateX(0);
}
/*#####Flipper styles END*/
.unitWithImage {
	float: left;
	width: 100%;
	margin-bottom: 22px;
	display: table;
}
.unitWithImage:last-child {
	margin-bottom: 0;
}

.unitWithImage > div {
	display: table-cell;
}
.unitWithImageImg {
	box-sizing: content-box;
}
.unitWithImage[size='100'] .unitWithImageImg {
	width: 100px;
	height: 100px;
}
.unitWithImage[size='250x130'] .unitWithImageImg {
	width: 250px;
	height: 130px;
}
.unitWithImageData {
	vertical-align: middle;
	padding-left: 15px;
}
.unitWithImageData h1 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-transform: none;
	margin: 0 0 8px 0;
}
#orderDetails .unitWithImageData h1 {
	margin-bottom: 20px;
}
.unitWithImageData a {
	text-decoration: none;
}
.unitWithImageData p {
	margin: 0;
	line-height: 13px;
}
.unitWithImageData p.date {
	margin: -3px 0 12px 0;
	font-weight: 500;
}
.flipperFlip .unitWithImageData p.date {
	float: left;
	margin: -3px 0 9px 0;
	padding-bottom: 20px;
	line-height: 30px;
}
.unitWithImageData p.date span {
	font-size: 13px;
	font-weight: 400;
}
.unitWithImageData .dateRangeTo {
	width: 100%;
	margin: 0;
}
.unitWithImageData svg {
	fill: #6d7c9b;
	width: 25px;
	height: 25px;
	margin: 0 4px;
}

/*****Unit With Image styles END*/
/*****Unit Focused styles START*/
.unitFocused,
.unitFocusedData {
	float: left;
	width: 100%;
}
.unitFocused {
	position: relative;
	margin-bottom: 30px;
}
.unitFocused:last-child {
	margin-bottom: 0;
}
.unitFocused h1 {
	font-size: 18px;
	height: 22px;
	overflow: hidden;
	line-height: 20px;
	text-transform: none;
	margin-bottom: 15px;
}
.unitFocused h1 a {
	text-decoration: none;
}
.unitFocusedImg {
	float: left;
	width: 250px;
	height: 130px;
	overflow: hidden;
}
.unitFocusedData {
	border: solid 1px #e9edf4;
	display: table;
}
.unitFocusedDataCore,
.unitFocusedImg {
	display: table-cell;
	float: none;
}
.unitFocusedImg {
	border-right: solid 1px #e9edf4;
}
.unitFocusedDataCore {
	vertical-align: middle;
	text-align: left;
	padding-left: 20px;
	max-width: 120px;
	background-color: #ffffff;
}
.unitFocusedDataCore p {
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	margin: 0;
}
.unitFocusedDataCore p.upcomingCount {
	font-size: 44px;
	line-height: 35px;
	font-weight: 100;
	margin-bottom: 5px;
}
.unitFocusedDataCore p.upcomingCount + p {
	margin-right: 5px;
}
.unitFocused[type='event'] .unitFocusedDataCore {
	padding: 6px;
}
.unitFocused[type='event'] .unitFocusedDataCore p {
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 10px;
}
.unitFocused[type='event'] .unitFocusedDataCore p span {
	font-size: 20px;
}
.unitFocused:hover {
	transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
}
.unitFocused:hover .unitFocusedData {
	border: solid 1px #bac7dd;
}
.unitFocused:hover .unitFocusedImg {
	border-right: solid 1px #bac7dd;
}
/*****Unit Focused styles END*/
/*****Unit Date Block style START*/
.unitByDateDateBlock {
	width: 70px;
}
.unitByDateDateBlock p {
	color: #ffffff;
	margin: 0;
	text-align: center;
}
.unitByDateDateBlockDate,
.unitByDateDateBlockTime {
	float: left;
	width: 100%;
	padding: 10px 0;
}
.unitByDateDateBlockDate p {
	font-weight: 300;
}
.unitByDateDateBlockDate p.dayName {
	font-size: 14px;
	line-height: 12px;
	margin-bottom: 8px;
}
.unitByDateDateBlockDate p.day {
	font-size: 28px;
	line-height: 22px;
	margin-bottom: 4px;
}
.unitByDateDateBlockDate p.month {
	font-size: 16px;
	line-height: 13px;
	font-weight: 500;
}
.unitByDateDateBlockTime {
	background: url(../images/dateBorder.png) repeat-x top left;
}
.unitByDateDateBlockTime p {
	font-size: 12px;
	line-height: 9px;
}
/*****Unit Date Block style END*/
.imagesBlocks a {
	float: left;
	width: calc(50% - 7px);
	margin: 0 13px 13px 0;
}
.imagesBlocks a:nth-child(even) {
	margin-right: 0;
}
.imagesBlocks a:hover {
	transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
}
.imagesBlocks a img {
	float: left;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/*****Categories Styles START*/
.categoryFeaturedEvent {
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	padding: 50px 20px;
	background-color: rgba(0, 0, 0, 0.07);
}
.categoryFeaturedEvent h1,
.categoryFeaturedEvent .unitFocused h1 a {
	color: #ffffff;
}
/*****Categories Styles END*/
/*****Events List Styles START*/
/*#####Events List Filters Styles START*/
.eventsListFilter {
	float: left;
	width: 100%;
	padding: 10px 10px 10px 20px;
	margin-bottom: 10px;
	background-color: #f5f7fb;
	border-left: solid 10px #214387;
}
.eventsListFilter:hover {
	background-color: #eceff5;
}
.eventsListFilterUnit,
.eventsListFilterUnit p,
.eventsListFilterUnit select {
	float: left;
}
.eventsListFilterUnit {
	border-right: solid 1px #d5dce2;
	margin-right: 30px;
	padding-right: 30px;
}
.eventsListFilterUnit:last-child {
	margin-right: 0;
	padding-right: 0;
	border-right: 0;
}
.eventsListFilterUnit p {
	float: left;
	margin: 8px 20px 0 0;
}
.eventsListFilterUnit select,
.eventsListFilterUnit input {
	padding: 10px;
	border: none;
	font-size: 14px;
	width: 158px;
}
.eventsListFilterUnit select[disabled] {
	opacity: 0.7;
}
/*#####Events List Filters Styles START*/
.eventsList {
	float: left;
	width: 100%;
	text-align: left;
	border-collapse: collapse;
}
.eventsList th:not(.filter) {
	text-align: left;
	padding: 20px 10px;
}
.eventsList td {
	padding: 15px 10px 15px 10px;
	border: 0;
	border-top: solid 1px #e9edf4;
}
.eventsList td select {
	width: 40px;
}
.eventsList td.dateCell {
	padding: 10px !important;
	width: 200px;
}
.eventsList td.dateCell.mini {
	width: 150px;
}
.eventsList td.priceCell {
	padding: 10px !important;
	width: 150px;
}
.eventsList td.boughtCell {
	width: 160px;
}
.eventsList td:last-child {
	padding-right: 10px;
}
.eventsList h1 {
	margin: 0;
	font-size: 16px;
	line-height: 22px;
	text-transform: none;
	font-weight: 500;
}
.eventsList h1,
.eventsList h1 a {
	color: #4b5b7d;
}
.eventsList h1 a:hover {
	color: #214387;
}
.eventsList a {
	text-decoration: none;
}
.eventsList p {
	margin: 0;
}
.eventsList p.eventName {
	font-size: 14px;
	margin-top: 5px;
}
.eventsList p.avTickets {
	color: #9ca6b9;
	font-size: 14px;
}
.eventsListEventCell {
	max-width: 250px;
}
.eventsList tbody tr {
	transition: all ease-out 200ms;
	-webkit-transition: all ease-out 200ms;
}
body:not(.isMobile) .eventsList tbody tr:hover {
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	transform: translateY(-2px) translateX(-2px);
	-webkit-transform: translateY(-2px) translateX(-2px);
}
body:not(.isMobile) .eventsList tbody tr:hover td {
	background-color: #f5f7fb;
}
.ng-table th.sortable.sort-asc,
.ng-table th.sortable.sort-desc {
	background-color: transparent;
}
.ng-table-pager {
	float: left;
	width: 100%;
	border-top: solid 5px #e9edf4;
	display: none;
}
.ng-table-pager ul {
	float: right;
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.ng-table-pager ul li {
	float: left;
}
.ng-table-pager ul li a {
	font-size: 28px;
	text-decoration: none;
	padding: 10px 5px;
	color: #e6ecf8;
}
.ng-table-pager ul li.active a,
.ng-table-pager ul li a:hover {
	color: #a5b2cc;
}
/*****Events List Styles END*/
/*****Tickets List Styles START*/
.ticketsDataTable {
	float: left;
	width: 50%;
}
td.priceCell h1 {
	font-size: 25px;
}
.ticketsDataTable td.ticketsBuyCell,
.ticketsDataTable td.ticketsBuyCell .globalButton.borderedButton {
	width: 60px;
}
.ticketsDataTable td.qtySelect {
	width: 110px;
}
.ticketsDataTable td.qtySelect select {
	padding: 3px 10px;
	font-size: 16px;
	width: 60px;
	text-align: center;
	border: solid 1px #e9edf4;
}
.ticketsListlegend {
	display: inline-block;
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
	color: #909fbf;
}
.ticketsDataTable td.qtySelect .ticketsListlegend {
	text-align: center;
}
/*****Tickets List Styles END*/
.price span {
	font-size: 20px;
	line-height: 12px;
	display: inline-block;
	vertical-align: top;
}
.performenceSeatingMap {
	float: right;
	width: calc(50% - 20px);
	border: solid 1px #e9edf4;
}
.performenceSeatingMap img {
	width: 100%;
	float: left;
}
.titleSocials {
	float: right;
	margin-top: -20px;
}
.titleSocials a {
	float: left;
	margin-left: 10px;
	border: solid 1px #e9edf4;
	padding: 10px;
}
.titleSocials a svg {
	float: left;
	width: 30px;
	height: 30px;
}
.titleSocials a.facebook {
	fill: #3b5998;
}
.titleSocials a.facebook:hover {
	fill: #ffffff;
	background-color: #3b5998;
}
.titleSocials a.twitter {
	fill: #00aced;
}
.titleSocials a.twitter:hover {
	fill: #ffffff;
	background-color: #00aced;
}
.titleSocials a.google {
	fill: #dd4b39;
}
.titleSocials a.google:hover {
	fill: #ffffff;
	background-color: #dd4b39;
}
/*****Themes Styles START*/
body[theme='music'] nav ul li[category='music'] a,
body[theme='festivals'] nav ul li[category='festivals'] a,
body[theme='sports'] nav ul li[category='sports'] a,
body[theme='theater'] nav ul li[category='theater'] a,
body[theme='comedy'] nav ul li[category='comedy'] a {
	color: #ffffff;
}
body[theme='music'] nav li[category='music'] .navSubCategories,
body[theme='festivals'] nav li[category='festivals'] .navSubCategories,
body[theme='sports'] nav li[category='sports'] .navSubCategories,
body[theme='theater'] nav li[category='theater'] .navSubCategories,
body[theme='comedy'] nav li[category='comedy'] .navSubCategories {
	/*-----No need to show current category dropdown if it's already there*/
	display: none !important;
}
body[theme='music'] nav ul li[category='music'] a,
body[theme='festivals'] nav ul li[category='festivals'] a,
body[theme='sports'] nav ul li[category='sports'] a,
body[theme='theater'] nav ul li[category='theater'] a,
body[theme='comedy'] nav ul li[category='comedy'] a {
	cursor: default;
}
/*#####Music*/
body[theme='music'] {
	background-image: url(../images/BGS/music.jpg);
}
body[theme='music'] nav ul li[category='music'],
body[theme='music'] .mainView .navSubCategories {
	background-color: #ce4f4f;
}
body[theme='music'] .eventsListFilter,
.categoriesListingGroup[category='music'] h1 {
	border-color: #ce4f4f;
}
body[theme='music'] .eventsList .dateDayFocusDay p,
body[theme='music'] .contentsTitle h1 a {
	color: #ce4f4f;
}
/*#####Festivals*/
body[theme='festivals'] {
	background-image: url(../images/BGS/festivals.jpg);
}
body[theme='festivals'] nav ul li[category='festivals'],
body[theme='festivals'] .mainView .navSubCategories {
	background-color: #8900a2;
}
body[theme='festivals'] .eventsListFilter,
.categoriesListingGroup[category='festivals'] h1 {
	border-color: #8900a2;
}
body[theme='festivals'] .eventsList .dateDayFocusDay p,
body[theme='festivals'] .contentsTitle h1 a {
	color: #8900a2;
}
/*#####Sports*/
body[theme='sports'] {
	background-image: url(../images/BGS/sports.jpg);
	background-size: cover;
}
body[theme='sports'] nav ul li[category='sports'],
body[theme='sports'] .mainView .navSubCategories {
	background-color: #2e8c40;
}
body[theme='sports'] .eventsListFilter,
.categoriesListingGroup[category='sports'] h1 {
	border-color: #2e8c40;
}
body[theme='sports'] .eventsList .dateDayFocusDay p,
body[theme='sports'] .contentsTitle h1 a {
	color: #2e8c40;
}
/*#####Theater*/
body[theme='theater'] {
	background-image: url(../images/BGS/theater.jpg);
	background-size: cover;
}
body[theme='theater'] nav ul li[category='theater'],
body[theme='theater'] .mainView .navSubCategories {
	background-color: #009eb1;
}
body[theme='theater'] .eventsListFilter,
.categoriesListingGroup[category='theater'] h1 {
	border-color: #009eb1;
}
body[theme='theater'] .eventsList .dateDayFocusDay p,
body[theme='theater'] .contentsTitle h1 a {
	color: #009eb1;
}
/*#####Comedy*/
body[theme='comedy'] {
	background-image: url(../images/BGS/comedy.jpg);
	background-size: cover;
}
body[theme='comedy'] nav ul li[category='comedy'],
body[theme='comedy'] .mainView .navSubCategories {
	background-color: #998f4f;
}
body[theme='comedy'] .eventsListFilter,
.categoriesListingGroup[category='comedy'] h1 {
	border-color: #998f4f;
}
body[theme='comedy'] .eventsList .dateDayFocusDay p,
body[theme='comedy'] .contentsTitle h1 a {
	color: #998f4f;
}
/*****Themes Styles END*/

/*****CategoriesListing Styles START*/
.categoriesListingGroup h1 {
	border-left: solid 7px #13235f;
	padding-left: 10px;
}
.categoriesListingGroupLinks {
	float: left;
	width: 100%;
	margin-left: 17px;
}
.categoriesListingGroupLinks a {
	float: left;
	width: 33.33%;
	font-size: 18px;
	padding: 7px 0;
	text-decoration: none;
}
/*****CategoriesListing Styles END*/
/*****Contacts Bar Styles START*/
.contactBar {
	margin-bottom: 20px;
}
.contactBar span {
	display: inline-block;
	margin-right: 20px;
}
.contactBar h2 {
	float: left;
	font-size: 18px;
	margin: 10px 0 0 0;
	text-transform: none;
	color: #9ca7bf;
}
.contactBar svg {
	float: left;
	margin: -5px 5px 0 0;
	fill: #9ca7bf;
	width: 24px;
	height: 24px;
}
.contactBar:hover h2 {
	color: #6d7c9b;
}
.contactBar:hover svg {
	fill: #6d7c9b;
}
/*****Contacts Bar Styles END*/
/*****Event Rules Styles START*/
.featuredEventsRules {
	float: left;
	width: 100%;
	margin: 30px 0;
	padding-top: 26px;
	border-top: solid 1px rgba(255, 255, 255, 0.2);
}
.featuredEventsRules h2 {
	margin-bottom: 20px;
}
.featuredEventsRulesUnits {
	float: left;
	width: 100%;
}
.featuredEventsRule {
	position: relative;
	display: inline-block;
	margin-right: 10px;
	border: solid 1px rgba(255, 255, 255, 0.1);
	padding: 5px;
}
.featuredEventsRule svg {
	float: left;
	width: 30px;
	height: 30px;
	fill: #ffffff;
}
.featuredEventsRule:hover {
	background-color: rgba(255, 255, 255, 1);
}
.featuredEventsRule:hover svg {
	fill: #214387;
}

/*****Event Rules Styles END*/
.banner {
	float: left;
	width: 100%;
	max-height: 313px;
	overflow: hidden;
}
.banner img {
	float: left;
	width: 100%;
	background-size: cover;
}
.banner.withMap {
	height: 313px;
}
.banner.withMap img {
	width: 400px;
	height: 100%;
}
.bannerMap {
	float: left;
}
.banner.withMap .bannerMap {
	width: calc(100% - 400px);
	height: 313px;
}

.breadCrumb {
	margin-bottom: 40px;
}
.breadCrumb p {
	margin: 9px 0;
}
.breadCrumb svg {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	fill: #6d7c9b;
}

/*****Account Styles START*/
.accountRow {
	float: left;
	width: 100%;
	padding: 0 10px;
}
:not(.profileSwitchView) > .accountRow:not(.active) {
	cursor: pointer;
}
body:not(.isMobile) .accountRow:not(.active):hover {
	background-color: #f5f7fb;
}
.accountRowLabel {
	float: left;
	width: 250px;
}
.accountRowLabel p,
.accountRowValueData p {
	margin: 20px 0;
}
.accountRowValueData img {
	width: 200px;
    padding-top: 10px;
}
.accountRowValue {
	float: left;
}
.accountRowValue input[type=file] {
    display: block;
	height: initial !important;
	padding: 0 !important;
	border: none !important;
}
.accountRowValue input[type=file], .accountRowValue input[type=file] ~ .cancelEdit {
	margin-top: 20px;
}
.accountRowValueData {
	float: left;
}
.accountRowControls {
	float: right;
}
.accountRow svg {
	float: right;
	width: 30px;
	height: 30px;
	margin-top: 12px;
	fill: #e4e7ec;
}
body.isMobile .accountRow svg,
.accountRow:hover svg {
	fill: #6d7c9b;
}
.accountRowControls p {
	float: right;
	font-size: 14px;
	margin: 15px 10px 0 0;
	opacity: 0;
	transform: translateX(20px);
	-webkit-transform: translateX(20px);
}
body:not(.isMobile) .accountRow:hover .accountRowControls p {
	opacity: 1;
	transform: translateX(0);
	-webkit-transform: translateX(0);
}
.accountRow .globalForm {
	display: none;
}
/*##### Account Row Active Styles*/
:not([ng-form=nationalIdForm]).active .accountRowControls {
	display: none;
}
.active .accountRowValueData {
	display: none;
}
.active .globalForm {
	display: block;
}

a.cancelEdit {
	float: left;
	margin: 10px;
	font-size: 14px;
}
p.accountError {
	float: right;
	color: #ea2525;
	margin: 20px;
	font-size: 14px;
}
p.accountError.mini {
	margin: 10px;
}
.profileSwitchView:not(.active) .globalButtonsBar {
	display: none;
}

/*****Account Styles END*/
.resetTotal {
	text-align: right;
	color: #414856;
}
.resetTotal span {
	font-size: 20px;
	margin-left: 10px;
}
.paymentMethods {
	/* display: flex;
    align-items: center;
    justify-content: space-between; */
}
.paymentMethods .globalFormUnitRadio {
	/* margin-right: 30px; */
}
.paymentMethods .globalFormUnitRadio .globalFormUnitCheckCore {
	margin: 17px 10px 0 0;
}
.paymentMethods .globalFormUnitRadio img {
	margin-right: 15px;
}
.paymentMethods .globalFormUnitCheck:not(.globalFormUnitRadio) {
	float: right;
}
.paymentMethods .globalFormUnitRadio {
	display: flex;
    align-items: center;
}
.orderCountDown {
	float: right;
	margin-top: -20px;
}
.orderCountDown span {
	font-weight: 200;
	font-size: 45px;
	color: #b14c4c;
}

.paymentSuccess svg {
	position: absolute;
	right: 0;
	top: 0;
	width: 260px;
	height: 150px;
	fill: #358138;
}

/*****Invoices Styles START */
.invoice {
	float: left;
	width: 700px;
	padding-bottom: 30px;
	color: #000000;
	font-size: 14px;
	background-color: #ffffff;
	border: solid 1px #000000;
	display: none;
}
.invoice.show {
	display: block;
}
.invoiceHead p,
.invoice h1 {
	margin: 0 0 3px 0;
}
.invoice p span {
	font-weight: bold;
}
.invoiceHead,
.invoiceBody {
	float: left;
	width: 100%;
}
.invoiceHeadData {
	float: right;
}
.invoiceHead img {
	float: left;
	height: 66px;
}
.invoiceUnit {
	float: left;
	width: calc(100% - 24px);
	margin: 10px;
	border-right: solid 2px #000000;
	border-left: solid 2px #000000;
	border-bottom: solid 2px #000000;
}
.invoiceHead,
.invoiceUnitTitle {
	padding: 10px;
}
.invoiceUnitTitle {
	border-bottom: solid 2px #000000;
	border-top: solid 2px #000000;
}
.invoiceUnitTitle h1 {
	text-align: center;
	margin: 0;
}
.invoiceUnitBody {
	padding: 20px;
}
.invoiceTotals p {
	text-align: right;
}
.invoiceTotals p span {
	font-weight: normal;
	width: 80px;
}
.invoice p.dashes {
	letter-spacing: 1px;
	font-size: 20px;
	font-weight: 100;
}

.invUp {
	float: left;
	width: 100%;
	margin-top: 20px;
}
/*****Invoices Styles END */
.eventPerformers a {
	margin-right: 10px;
}
/*Contents Styles END*/

/*Footer Styles START*/
footer {
	float: left;
	width: 100%;
	margin-bottom: 50px;
	background-color: #00091b;
}
.footerLinksGroups {
	float: left;
	padding: 40px 50px;
	margin: 10px 0;
	border-right: solid 1px #081a4d;
}
.footerLinksGroups:last-child {
	border: 0;
}
.footerLinksGroup {
	font-weight: 300;
}
.footerLinksGroup h1 {
	color: #ffffff;
	margin-bottom: 15px;
	font-weight: 300;
}
.footerLinksGroup,
.footerLinksCore {
	float: left;
	margin-right: 40px;
}
.footerLinks {
	float: left;
	border-top: solid 1px #081a4d;
	padding-top: 15px;
	padding-right: 30px;
}
.footerLinksGroups.rights {
	padding-bottom: 0;
}
.footerLinksGroups.rights .footerLinks {
	padding-right: 0;
}
.footerLinksCore a {
	display: block;
	font-size: 16px;
	color: #ffffff;
	text-decoration: none;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.footerLinksCore a:hover {
	text-decoration: underline;
}
.footerLinksGroup:last-child,
.footerLinksCore:last-child,
.footerLinksCore a:last-child,
.footerLinksCore.social a:last-child {
	margin: 0;
}
.footerLinksCore.social a {
	float: left;
	margin-right: 5px;
}
.footerLinksCore.social svg {
	float: left;
	fill: #ffffff;
	width: 30px;
	height: 30px;
}
.footerLinksCore.social a:hover svg {
	transform: scale3d(1.2, 1.2, 1);
	-webkit-transform: scale3d(1.2, 1.2, 1);
}
.rightsGroupsHolder,
.copy {
	float: left;
	width: 100%;
}
.copy {
	clear: both;
	margin-top: 40px;
	text-align: center;
	color: #ffffff;
}
.copy p {
	margin: 0;
}
.copy a {
	margin-top: 5px;
	display: inline-block;
	vertical-align: middle;
}
.copy a:hover {
	transform: translateY(-2px);
	-webkit-transform: translateY(-2px);
}
/*Footer Styles END*/
/*Sold Tickets Financials Styles START*/
.soldTicketsFinancials {
	text-align: center;
	margin-bottom: 40px;
	border: solid 5px #f5f7fb;
	padding: 30px 0;
}
.soldTicketsFinancials svg {
	width: 100px;
	height: 100px;
	fill: #e7e8ea;
}
.soldTicketsFinancials svg,
.soldTicketsFinancialsValue {
	display: inline-block;
	vertical-align: middle;
}
.soldTicketsFinancialsValue {
	margin-left: 10px;
}
.soldTicketsFinancialsValue .price {
	font-size: 32px;
	line-height: 40px;
	font-weight: 500;
	margin-bottom: 0;
}
.soldTicketsFinancialsValue p {
	margin-top: 0;
	text-align: left;
}
.soldTicketsFinancialsValue .price span {
	line-height: 30px;
}

/*Sold Tickets Financials Styles END*/
/*TEMP FOR SHOWING DATA ON WHITE BACKGROUND WILL BE DELETED SOON*/
.centerDiv {
	background-color: #ffffff;
	padding: 20px;
	float: left;
	width: 100%;
}
.addMoreSpace {
	width: 60%;
}
.loginPanelHalf .allow-dropdown {
	width: 100%;
}
#result {
	width: 100%;
	text-align: center;
	line-height: 30px;
}
.categoriesListingGroup h1 {
	margin-bottom: 0;
	line-height: 30px;
}
.categoriesListingGroup {
	width: calc(50% - 40px);
}

.paymentFrameHolder {
	float: left;
	width: 100%;
	position: relative;
}
.paymentPreloader {
	position: absolute;
	top: 50%;
	transform: translate3d(0, -60%, 0);
	width: 100%;
}
.spinner {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;

	-webkit-animation: sk-rotate 2s infinite linear;
	animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: #333;
	border-radius: 100%;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
	top: auto;
	bottom: 0;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.changeFloatingOnPaymentDetails {
	float: right;
}
@-webkit-keyframes sk-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes sk-rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.rtlContents {
	direction: rtl;
}

.iti-mobile .intl-tel-input.iti-container {
	top: 89px;
    bottom: 0;
    left: 0;
    right: 0;
}

.iti-flag {
	background-image: url('/assets/images/flags.png');
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (min--moz-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2 / 1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx) {
	.iti-flag {
		background-image: url('/assets/images/flags@2x.png');
	}
}

.editWidthFourUnit {
	width: calc(25% - 60px / 4);
	margin-right: 20px !important;
}
.editWidthFourUnit:last-child {
	margin-right: 0 !important;
}
.contentsBorderedRight.editWidthFourUnit {
	border-right: solid 1px #e9edf4;
}
.editWidthFourUnit .soldTicketsFinancialsUnit img {
	display: inline-block;
	vertical-align: middle;
	width: 36.1%;
}
.contentsBorderedRight.editWidthFourUnit .contentsCore {
	border-right: none;
}

.d-flex {
	display: flex !important;
}

.d-none {
	display: none !important;
}

.invisible {
	visibility: hidden !important;
}

#registerForm {
	overflow-x: hidden;
};
/**responsive.css**/
.errorVerifyPage {
    margin-right: 172px;
}

.errorChangeNumVerifyPage {
    margin-right: 100px;
}

@media only screen and (max-width:1240px) {
    .mainDiv {
        width: calc(100% - 40px);
    }

    body.loginOpened header {
        width: 100%;
    }

    .navSubCategories {
        width: 100%;
    }

    .featuredEventsCarouselNavUnitFocusImage {
        width: 80px;
        height: 80px;
    }

    .featuredEventsCarouselNavUnitFocusCore {
        margin-top: 5px;
        width: calc(100% - 80px - 5px);
    }

    .featuredEventsCarouselNavUnitFocusCoreSeats,
    .featuredEventsCarouselNavUnitFocusCoreSeats p {
        margin-top: 10px;
    }

    .imagesBlocks a {
        width: calc(50% - 6px);
        margin: 0 12px 12px 0;
    }

    .imagesBlocks a img {
        width: 100%;
    }

    .unitFocused[type="event"] .unitFocusedDataCore p span {
        font-size: 16px;
    }

    .unitFocusedDataCore {
        padding-left: 10px;
    }

    .footerLinksGroups {
        width: 100%;
        padding: 40px 20px;
        border: none;
        text-align: center;
        border-bottom: solid 1px #081a4d;
    }

    .footerLinksGroup {
        float: none;
        display: inline-block;
        text-align: left;
    }

    .footerLinksGroup,
    .footerLinksCore {
        margin-right: 20px;
    }

    .footerLinks {
        width: 100%;
    }

    .footerLinksGroups:last-child .footerLinksGroup h1 {
        text-align: center;
    }

    .eventsListFilter {
        padding: 10px;
    }

    /*Events List Filters*/
    .eventsListFilterIdentifier {
        display: table;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit {
        display: table-cell;
        float: none;
        margin: 0;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit:not(:last-child) {
        padding-right: 20px;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit:not(:first-child) {
        padding-left: 20px;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit p {
        margin: 10px 10px 10px 0;
    }
}

/*Handle carousel thumbnails date and seats*/
@media only screen and (max-width:1160px) and (min-width:1000px),
only screen and (max-width:900px) {
    .dateDayFocusDay {
        margin-right: 5px;
    }

    .dateDayFocusDay p {
        font-size: 32px;
    }

    .dateDayFocusRest p {
        font-size: 14px;
    }

    .featuredEventsCarouselNavUnitFocusCoreSeats p span {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1000px) {
    .paymentMethods {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

/*When login panel view become scrollable not fixed*/
@media only screen and (max-height:768px),
only screen and (max-width:1190px) {
    body.loginOpened header {
        position: initial;
    }

    body.loginOpened .loginView {
        height: auto;
        overflow: visible;
    }

    .loginView {
        position: absolute;
    }

    .overlay {
        position: fixed;
        display: none;
    }

    body.loginOpened .overlay,
    .globalPopup .overlay {
        display: inline-block;
    }

}

@media only screen and (max-width:1190px) {

    h1,
    h2 {
        font-size: 20px;
        line-height: 16px;
        margin: 0 0 20px 0;
    }

    p {
        font-size: 14px;
    }

    .contentsTitle.withSide {
        margin-bottom: 10px;
    }

    .contentsCore.textData p {
        font-size: 16px;
    }

    .globalButton.borderedButton {
        width: 92px;
        font-size: 13px;
    }

    .headerSearchField {
        width: 400px;
    }

    .loginPanel {
        width: calc(100% - 40px);
        padding: 60px 40px;
    }

    .loginPanelHalf {
        width: calc(50% - 2px);
        padding: 0 40px 0 0;
    }

    .loginPanelHalf.bordered {
        padding: 0 0 0 40px;
    }

    .unitWithImageData h1,
    .unitFocused h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        max-height: 46px;
        overflow: hidden;
    }

    span.categoryTag {
        height: 17px;
    }

    .unitFocusedDataCore p {
        font-size: 16px;
    }

    .unitWithImageData {
        padding: 0 10px 0 15px;
    }

    .flipper .back .unitWithImageData {
        padding: 0 0 0 15px;
    }

    .unitFocused[type="event"] .unitFocusedDataCore p {
        font-size: 11px;
    }

    .unitFocused[type="event"] .unitFocusedDataCore p span {
        font-size: 14px;
    }

    .unitWithImageData p {
        height: 13px;
        font-size: 13px;
        overflow: hidden;
    }

    .titleSocials {
        margin-top: -10px;
    }

    .titleSocials a {
        padding: 5px;
    }

    /*Tickets Table*/
    .ticketsDataTable .eventsListFilterUnit {
        width: calc(50% - 5px);
        margin-right: 10px;
        padding-right: 10px;
    }

    .ticketsDataTable .eventsListFilterUnit:last-child {
        margin-right: 0;
        padding-right: 0;
    }

    .ticketsDataTable .eventsListFilterUnit select,
    .ticketsDataTable .eventsListFilterUnit input {
        width: calc(100% - 65px);
    }

    .ticketsDataTable .eventsListFilterUnit p {
        margin-right: 10px;
    }

    /*Events Table*/
    .eventsListIdentifier .globalButtonsBar {
        white-space: nowrap;
    }

    .eventsListIdentifier .globalButtonsBar a.globalButton {
        float: none;
    }

    .globalFormThreeUnits .globalFormUnit:last-child {
        margin-right: 20px;
    }

    .globalFormThreeUnits .globalFormUnit {
        width: calc(50% - 20px);
    }

    .soldTicketsFinancials svg {
        width: 85px;
        height: 85px;
    }

    .layoutThird.soldTicketsFinancialsUnitHolder {}
}

@media only screen and (max-width:1100px) {

    /*Events List Filters*/
    .eventsListFilterIdentifier .eventsListFilterUnit {
        width: 25%;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit p,
    .eventsListFilterIdentifier .eventsListFilterUnit input,
    .eventsListFilterIdentifier .eventsListFilterUnit select {
        width: 100%;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit p {
        margin: 0 0 10px 0;
    }
}

@media only screen and (max-width:1000px) {
    body {
        background-image: url(../images/BGS/defaultTablet.jpg);
    }

    .globalButton.callToAction {
        font-size: 24px;
    }

    header {
        padding: 15px 10px;
    }

    .layoutThird {
        width: calc(50% - 20px);
    }

    .headerSearchField {
        width: 300px;
    }

    .headerSearchField input[type="text"] {
        font-size: 16px;
    }

    nav a.sellTickets {
        height: 75px;
    }

    nav a.sellTickets span {
        font-size: 15px;
        line-height: 20px;
        padding: 25px 0 0 55px;
    }

    nav a.sellTickets span.sellTicketsHover {
        background-position: left -93px;
    }

    nav>ul>li>a {
        padding: 25px 18px;
    }

    .layoutThird:nth-child(3):not(.homePerformers):not(.soldTicketsFinancialsUnitHolder) {
        display: none;
    }

    .layoutThird:not(.soldTicketsFinancialsUnitHolder) .contentsCore {
        border-right: none;
    }

    .layoutThird .globalButtonsBar.vertical {
        text-align: left;
    }

    .layoutThird.homePerformers {
        width: 100%;
        border-top: solid 1px #e9edf4;
        padding-top: 50px;
        margin-top: 50px;
    }

    .loginPanelHolder {
        transform: translateY(80px);
        -webkit-transform: translateY(80px);
    }

    .restorePasswordHandler {
        margin-top: 3px;
    }

    .navSubCategoriesGroup {
        margin-right: 50px;
    }

    .featuredEventsCarouselFeaturedUnitImage {
        height: 300px;
    }

    .featuredEventsCarouselFeaturedUnitIntro {
        height: 75px;
    }

    .featuredEventsCarouselFeaturedUnitData .globalButtonsBar:not(.edged) .globalButton {
        margin: 0 5px;
        padding: 10px 30px;
        line-height: 18px;
    }

    .featuredEventsRules {
        margin: 15px 0;
        padding-top: 15px;
    }

    .featuredEventsRule {
        padding: 5px;
        margin-right: 5px;
    }

    .featuredEventsRule svg {
        width: 22px;
        height: 22px;
    }

    .eventsList h1 {
        font-size: 14px;
    }

    .eventsList td {
        padding: 10px;
    }

    .eventsList td.priceCell {
        width: 90px;
    }

    td.priceCell h1 {
        font-size: 18px;
    }

    .price span {
        font-size: 12px;
    }

    .ticketsListlegend {
        font-size: 12px;
    }

    .eventsListIdentifier.eventsList td.dateCell {
        width: 150px;
        padding: 5px !important;
    }

    .dateDayFocus.inEventsList {
        padding-right: 15px;
        width: 135px;
        min-width: 0;
    }

    .eventsListEventCell {
        padding-left: 0;
    }

    .dateDayFocus.inEventsList .dateDayFocusDay p {
        font-size: 32px;
    }

    .dateDayFocus.inEventsList .dateDayFocusRest p {
        font-size: 12px;
    }

    .eventsListIdentifier p,
    .eventsListIdentifier p.avTickets {
        font-size: 12px;
    }

    .ClearContentsBorderedRight {
        border-right: none !important;
    }

    .editWidthFourUnit .soldTicketsFinancialsUnit img {
        width: 25%;
    }

    .contentsBorderedRight.editWidthFourUnit {
        margin-bottom: 15px;
    }

    .paymentMethods .globalFormUnitCheck:not(.globalFormUnitRadio) {
        margin-top: 20px;
    }

    .iti-mobile .intl-tel-input.iti-container {
        top: 79px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width:1000px) and (min-width:600px) {
    .layoutThird.homePerformers a {
        width: calc(32.9% - 6px);
    }

    .layoutThird.homePerformers a:nth-child(even) {
        margin-right: 12px;
    }

    .layoutThird.homePerformers a:nth-child(3n) {
        margin-right: 0;
    }
}

@media only screen and (max-width:920px) {

    h1,
    h2 {
        font-size: 20px;
    }

    .globalFormUnit {
        margin-bottom: 20px;
    }

    .globalButton.callToAction {
        padding: 15px 20px;
    }

    .restorePasswordHandler span {
        display: none;
    }

    .loginPanel {
        padding: 40px;
    }

    .loginOr {
        padding: 25px 0;
    }

    .loginOr h1 {
        font-size: 26px;
    }

    .globalButtonsBar.edged .globalButton {
        margin-bottom: 5px;
    }

    .extraInput .globalButton.callToAction {
        padding: 20px 30px;
    }

    .errorVerifyPage {
        margin-right: 29px;
    }

    .soldTicketsFinancials svg {
        width: 65px;
        height: 65px;
    }

    .soldTicketsFinancialsValue .price {
        font-size: 22px;
    }
}

@media only screen and (max-width:860px) {
    nav>ul>li>a {
        padding: 25px 14px;
    }

    a.logo {
        width: 150px;
    }

    a.logo img {
        width: 100%;
    }

    .headerSearch {
        height: 42px;
        margin-top: 10px;
    }

    .headerSearchField {
        width: 299px;
    }

    .headerSearchField input[type="text"] {
        font-size: 14px;
    }

    .headerSearch svg {
        width: 27px;
        height: 27px;
    }

    .headerUtlsLinks {
        margin-top: 7px;
    }

    .headerUtlsLinks a {
        padding: 10px 5px;
    }

    .headerUtlsLinks a.help span {
        display: none;
    }

    .loginPanelHolder {
        transform: translateY(66px);
        -webkit-transform: translateY(66px);
    }

    .navSubCategories {
        padding: 25px 15px;
        min-height: auto;
    }

    .navSubCategoriesGroups {
        width: 100%;
    }

    .navSubCategoriesGroup {
        margin-right: 0;
        width: 100%;
    }

    .navSubCategoriesGroup a {
        display: inline-block;
        width: 33.3%;
        font-size: 16px;
    }

    .navSubCategoriesGroup a,
    .navSubCategoriesGroup a:last-child,
    .navSubCategoriesGroup a:first-child {
        padding: 12px 0;
    }

    .featuredEventsCarouselNavUnitFocusCoreSeats p {
        text-align: left;
        margin-left: 5px;
    }

    .featuredEventsCarouselFeaturedUnitImage {
        height: 300px;
        width: calc(100% - 310px);
    }

    .featuredEventsCarouselFeaturedUnitData {
        width: 290px;
        padding-right: 5px;
    }

    .featuredEventsCarouselFeaturedUnitData h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .dateDayFocus.big .dateDayFocusDay p {
        font-size: 40px;
        line-height: 32px;
    }

    .dateDayFocus.big .dateDayFocusRest p {
        font-size: 18px;
        line-height: 18px;
    }

    .dateDayFocus.big .dateDayFocusRest p.day {
        font-size: 15px;
    }

    .featuredEventsCarouselFeaturedUnitVenue {
        margin-left: 10px;
    }

    .featuredEventsCarouselFeaturedUnitVenue svg {
        width: 42px;
        height: 42px;
        margin: -4px 5px 0 0;
    }

    .featuredEventsCarouselFeaturedUnitVenue p {
        font-size: 14px;
    }

    .featuredEventsCarouselFeaturedUnitImage {
        height: 273px;
    }

    .featuredEventsCarouselFeaturedUnitIntro {
        margin: 15px 0;
    }

    .eventsListIdentifier td:last-child:not(.eventsListEventCell) {
        width: 112px;
    }

    .eventsListIdentifier .globalButtonsBar {
        white-space: normal;
    }

    .eventsListIdentifier.eventsList p.eventName {
        font-size: 12px;
    }

    .dateDayFocus p.venueNameInDateCell {
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }

    .navSubCategoriesGroups.subsView .navSubCategoriesGroup {
        margin-right: 10px;
        width: calc(100% - 50px);
    }

    .contentsHalfResponsive {
        width: 60%;
    }

    .contentsHalfResponsiveRightSide {
        width: 40%;
    }

    .contentsHalfResponsiveRightSide img {
        width: 100%;
    }

    .iti-mobile .intl-tel-input.iti-container {
        top: 65px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width:809px) {
    .globalButtonsBar {
        float: none;
        display: inline-block;
    }

    .paymentMethods .globalFormUnitCheck:not(.globalFormUnitRadio) {
        float: left;
    }

    .globalButton.callToAction {
        float: none;
    }

    .globalFormUnitRadio.big img {
        width: 40%;
    }
}

@media only screen and (max-width:800px) {
    .globalButton.callToAction {
        font-size: 20px;
    }

    .loginPanel {
        padding: 40px 35px;
    }

    .loginPanelHalf {
        padding: 0 35px 0 0;
    }

    .loginPanelHalf.bordered {
        padding: 0 0 0 35px;
    }

    .errorChangeNumVerifyPage {
        margin-right: 70px;
    }
}

@media only screen and (max-width:768px) {
    body #scrollingArea {
        transition: all ease-out 200ms;
        -webkit-transition: all ease-out 200ms;
    }

    body.sideMenuActive #scrollingArea {
        position: fixed;
        transform: translateX(270px);
        -webkit-transform: translateX(270px);
    }

    .contentsTitle h1,
    .contentsTitle h2 {
        line-height: 30px;
        margin-bottom: 15px;
    }

    header {
        height: 153px;
    }

    nav {
        height: 13px;
    }

    .closeMenu {
        position: fixed;
        right: 0;
        top: -5px;
        height: 100vh;
        width: calc(100vw - 270px);
        z-index: 3;
        transform: translateX(100vw);
        -webkit-transform: translateX(100vw);
    }

    nav.activated .closeMenu {
        transform: translate(-230px);
        -webkit-transform: translate(-230px);
    }

    .closeMenu svg {
        float: right;
        fill: #ffffff;
        width: 40px;
        height: 40px;
        margin: 10px;
    }

    nav.activated:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        width: 100vw;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 1;
    }

    nav ul {
        width: 270px;
        top: -5px;
        position: fixed;
        z-index: 2;
        left: 0;
        background-color: #214387;
        height: 100vh;
        overflow-y: scroll;
        transform: translateX(-100vw);
        -webkit-transform: translateX(-100vw);
        transition: all ease-out 200ms;
        -webkit-transition: all ease-out 200ms;
    }

    nav.activated ul {
        transform: translateX(-270px);
        -webkit-transform: translateX(-270px);
    }

    nav ul li {
        float: left;
        width: 100%;
    }

    nav ul li a {
        float: left;
        width: 100%;
        border: none;
    }

    nav li[category="music"]>a,
    nav li[category="festivals"]>a,
    nav li[category="sports"]>a,
    nav li[category="theater"]>a,
    nav li[category="comedy"]>a {
        color: #ffffff;
        padding-bottom: 20px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    }

    nav ul li .navSubCategories {
        position: static;
        display: inline-block;
        padding: 10px 15px;
        border: none;
        margin: 0;
    }

    body[theme="music"] nav li[category="music"] .navSubCategories,
    body[theme="festivals"] nav li[category="festivals"] .navSubCategories,
    body[theme="sports"] nav li[category="sports"] .navSubCategories,
    body[theme="theater"] nav li[category="theater"] .navSubCategories,
    body[theme="comedy"] nav li[category="comedy"] .navSubCategories {
        display: inline-block !important;
    }

    nav ul li[category="music"],
    nav ul li[category="music"] .navSubCategories {
        background: #ce4f4f;
    }

    nav ul li[category="festivals"],
    nav ul li[category="festivals"] .navSubCategories {
        background: #8900a2;
    }

    nav ul li[category="sports"],
    nav ul li[category="sports"] .navSubCategories {
        background: #2e8c40;
    }

    nav ul li[category="theater"],
    nav ul li[category="theater"] .navSubCategories {
        background: #009eb1;
    }

    nav ul li[category="comedy"],
    nav ul li[category="comedy"] .navSubCategories {
        background: #998f4f;
    }

    nav ul li .navSubCategories .navSubCategoriesGroup a {
        width: 100%;
    }

    nav a.sellTickets {
        right: 0;
    }

    .contactBar h2 {
        font-size: 14px;
    }

    .banner img {
        min-height: 250px;
        object-fit: cover;
    }

    .banner.withMap img {
        width: 100%;
    }

    .loginPanel {
        padding: 40px 120px;
    }

    .loginPanelHalf {
        width: 100%;
        padding: 0;
    }

    .loginPanelHalf.bordered {
        border: none;
        padding: 0;
    }

    .headerSearch {
        width: calc(100% - 250px);
        margin-top: 81px;
        left: 80px;
        transform: none;
        -webkit-transform: none;
    }

    .headerSearchField {
        width: calc(100% - 55px);
    }

    .headerSearchField input[type="text"] {
        font-size: 13px;
    }

    .featuredEventsCarouselFeaturedUnit {
        position: relative;
    }

    .featuredEventsCarouselFeaturedUnitData {
        width: 100%;
        margin-left: 0;
    }

    .featuredEventsCarouselNavUnits,
    .featuredEventsCarouselFeaturedUnitIntro {
        display: none;
    }

    .featuredEventsCarouselFeaturedUnitVenueName {
        max-width: 200px;
    }

    .unitWithImageData,
    .flipper .back .unitWithImageData {
        padding: 0 10px 0 10px;
    }

    .unitFocusedDataCore .globalButtonsBar.vertical .globalButton {
        min-width: 100%;
    }

    .footerLinksGroups:first-child .footerLinksGroup:last-child {
        min-width: 307px;
        margin-right: 20px;
        margin-top: 40px;
    }

    .ticketsDataTable {
        width: 100%;
    }

    .contentsTitle.withSide:not(.withSideButton) h1 {
        width: 100%;
    }

    .contentsTitle.withSideButton h1 {
        width: calc(100% - 50px);
    }

    .titleSocials {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .titleSocials a {
        margin: 0 10px 0 0;
    }

    .titleSocials a.globalButton.borderedButton {
        padding: 14px;
        width: auto;
        margin-right: 0;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit:not(:first-child) {
        padding-left: 10px;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit:not(:last-child) {
        padding-right: 10px;
    }

    .accountRowControls p {
        display: none;
    }

    .accountRowLabel {
        width: 125px;
    }

    .editFontSize {
        font-size: 14px !important;
        margin-top: 10px !important;
    }

    .eventsList td.priceCell {
        width: 112px;
    }

    .eventsListEventCell .globalButton.borderedButton {
        margin-top: 10px;
    }

    .overlay .globalPopupCore {
        width: 90vw !important;
    }
}

@media only screen and (max-width:768px) and (min-width:621px) {
    .featuredEventsCarouselFeaturedUnitImage {
        width: calc(100% - 200px);
        height: 210px;
    }

    .featuredEventsCarouselFeaturedUnit .globalButtonsBar {
        position: absolute;
        right: 0;
        top: 0;
        width: 190px;
    }

    .featuredEventsCarouselFeaturedUnitData .globalButtonsBar:not(.edged) .globalButton {
        margin: 0;
        padding: 29px 64px;
        margin-bottom: 10px;
        line-height: 18px;
    }
}

@media only screen and (max-width:730px) {
    .contentsHalfResponsiveRightSide {
        display: none;
    }

    .contentsHalfResponsive,
    .contentsHalfChangeYourNum {
        width: 100%;
    }

    .errorVerifyPage {
        margin-right: 272px;
    }
}

@media only screen and (max-width:620px) {

    .featuredEventsCarouselFeaturedUnitImage {
        width: 100%;
        height: auto;
    }

    .featuredEventsCarouselFeaturedUnitImage img {
        height: auto;
    }

    .featuredEventsCarouselFeaturedUnitData {
        width: 100%;
        margin-left: 0;
    }

    .featuredEventsCarouselFeaturedUnit .globalButtonsBar {
        text-align: left;
        margin-top: 20px;
    }

    .featuredEventsCarouselFeaturedUnitData .globalButtonsBar:not(.edged) .globalButton {
        margin-left: 0;
    }

    .featuredEventsCarouselFeaturedUnitData,
    .featuredEventsCarouselFeaturedUnit .globalButtonsBar {
        text-align: center;
    }

    .dateDayFocus.white,
    .featuredEventsCarouselFeaturedUnitVenue,
    .dateRangeTo {
        float: none;
        display: inline-block;
    }

    .featuredEventsCarouselFeaturedUnitVenueName {
        max-width: 130px;
        text-align: left;
    }

    .contentsHalf {
        width: 100%;
    }

    .contentsHalf.endWithPadding {
        padding-right: 0;
    }

    .contentsHalf.startWithpadding {
        padding-left: 0;
        border-left: 0;
        margin-top: 15px;
    }

    .errorVerifyPage {
        margin-right: 142px;
    }
}

@media only screen and (max-width:622px) {
    .categoriesListingGroup {
        width: calc(100% - 40px);
    }
}

@media only screen and (max-width:622px) {
    .changeFloating {
        float: left !important;
    }
}

@media only screen and (max-width:600px) {
    body {
        background-image: url(../images/BGS/defaultMobile.jpg);
    }

    .loginPanel {
        padding: 40px 80px;
    }

    .sellTicketsMini {
        position: absolute;
        right: 0;
        bottom: 16px;
        color: #ffffff;
        font-weight: 500;
        background-color: #b14c4c;
        padding: 6px 9px;
        font-size: 13px;
        line-height: 14px;
    }

    .headerSearch {
        width: calc(100% - 160px);
        left: 70px;
    }

    .layoutThird {
        width: 100%;
        margin-right: 0;
    }

    .layoutThird:not(.popularVenue):not([category="music"]) {
        border-top: solid 1px #e9edf4;
        padding-top: 50px;
        margin-top: 50px;
    }

    .unitWithImage.back {
        width: calc(100% + 20px);
    }

    .contents {
        width: calc(100% - 30px);
        margin: 0 15px;
    }

    .navSubCategoriesGroup a {
        width: 50%;
    }

    .eventsListFilterIdentifier {
        border-left: 0;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit {
        display: inline-block;
        float: left;
        width: calc(50% - 5px);
        margin: 0;
        border-right: 0;
        padding: 5px 0 !important;
    }

    .eventsListFilterIdentifier .eventsListFilterUnit:nth-child(odd) {
        margin-right: 10px;
    }

    .eventsListFilterIdentifier.dateDayFocusRest {
        width: 100%;
        margin-top: 5px;
    }

    p.venueNameInDateCell,
    .eventsListIdentifier.eventsList p.avTickets {
        text-align: left;
        margin-top: 5px;
    }

    .eventsListIdentifier.eventsList td.dateCell {
        width: 95px;
    }

    .dateDayFocus.inEventsList {
        width: 85px;
        padding-right: 5px;
    }

    .eventsList td.dateCell {
        width: 110px;
    }

    .globalFormThreeUnits .globalFormUnit:last-child,
    .globalFormThreeUnits .globalFormUnit {
        margin-right: 20px;
    }

    .globalFormThreeUnits .globalFormUnit {
        width: 100%;
    }

    .globalFormTwoUnits .globalFormUnit {
        width: 100%;
    }

    .globalFormTwoUnits .globalFormUnit:first-child {
        margin-right: 0;
    }

    .soldTicketsFinancials {
        padding-top: 0;
    }

    .layoutThird.soldTicketsFinancialsUnitHolder:first-child {
        margin-top: 0 !important;
        border-top: 0;
    }

    .editWidthFourUnit .soldTicketsFinancialsUnit img {
        width: 20%;
    }
}

@media only screen and (max-width:500px) {
    .loginPanel {
        padding: 40px;
    }

    .active .accountRowLabel {
        width: 100%;
    }

    .active .accountRowLabel p,
    .active .accountRowValueData p {
        width: 100%;
        margin-bottom: 0;
    }

    .editWidthResponsive {
        width: 62px !important;
    }

    .eventsList td.dateCell.mini {
        width: 110px;
    }

    .globalFormUnitCheckMini p {
        width: 80%;
    }

    .accountRowValueData {
        width: 162px;
        /* overflow: hidden;
    text-overflow: ellipsis; */
    }

    .contentsHalfResponsive .contentsCore .globalFormUnit.sidedButton input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
    .globalFormUnit.sidedButton select {
        width: 183px;
    }

    .contentsHalfResponsive .extraInput .globalButton.callToAction {
        padding: 20px 15px;
    }

    .paymentMethods .globalFormUnitRadio.big img {
        width: 38%;
    }

    .paymentMethods .globalFormUnitRadio .globalFormUnitCheckCore {
        width: 35px;
        height: 35px;
        margin: 5px 10px 0 0;
    }

    .featuredEventsCarousel.noResults img {
        width: 80%;
    }

    .intl-tel-input .country-list {
        white-space: nowrap;
    }
}

@media only screen and (max-width:484px) {
    .paymentMethods .globalFormUnitRadio.big img {
        width: 36%;
    }
}

@media only screen and (max-width:484px) {
    .ChangeYourNum {
        margin-top: 15px;
    }
}

@media only screen and (max-width:420px) {

    h1,
    h2 {
        font-size: 18px;
    }

    header {
        padding: 5px;
        height: 144px;
    }

    .headerUtlsLinks .loggedInMenu>a {
        padding: 5px;
        margin-top: 5px;
    }

    .headerUtlsLinks a.help {
        display: none;
    }

    .mainDiv {
        width: calc(100% - 10px);
    }

    .loginPanel {
        width: 100%;
    }

    .loginPanelHolder {
        transform: translateY(56px);
        -webkit-transform: translateY(56px);
    }

    .loginPanel {
        padding: 40px 10px;
    }

    .mobileMenuHandler {
        bottom: 20px;
        left: 10px;
    }

    .sellTicketsMini {
        bottom: 16px;
        right: 10px;
        padding: 7px 9px;
    }

    nav.activated .closeMenu {
        transform: translate(-260px);
        -webkit-transform: translate(-260px);
    }

    .ticketCutEdgeVertical {
        display: none;
    }

    .headerSearch {
        width: calc(100% - 145px);
        left: 58px;
    }

    .headerSearch button {
        border-left: solid 1px #e9edf4;
    }

    .headerSearchField {
        width: calc(100% - 48px);
    }

    .navSubCategoriesGroups h1 {
        font-size: 18px;
    }

    .navSubCategoriesGroups h1 a {
        transform: translateX(0px) translateY(-10px);
        -webkit-transform: translateX(0px) translateY(-10px);
    }

    .navSubCategoriesGroups h1 svg {
        width: 35px;
        height: 35px;
    }

    .loggedInMenuDrop {
        top: 40px;
    }

    .mainView .navSubCategoriesGroup a,
    .mainView .navSubCategoriesGroup a:last-child,
    .mainView .navSubCategoriesGroup a:first-child {
        font-size: 15px;
        padding: 8px 0;
    }

    .footerLinksGroups:first-child .footerLinksGroup,
    .footerLinksGroups:first-child .footerLinksGroup:last-child {
        width: 100%;
        min-width: 0;
        margin-top: 40px;
    }

    .footerLinksGroup:first-child {
        margin-top: 0;
    }

    .footerLinksGroup:last-child .footerLinksCore:first-child {
        margin-bottom: 10px;
    }

    .ticketsQtyMobile {
        float: left;
        margin-top: 7px;
    }

    .ticketsQtyMobile select {
        width: 45px;
        height: 33px;
        margin-right: 10px;
    }

    .ticketsDataTable td {
        padding: 5px !important;
        vertical-align: top;
    }

    .ticketsListlegend {
        margin-top: 0;
        width: auto;
    }

    .ticketsDataTable td .globalButtonsBar {
        margin-top: 5px;
        float: left !important;
    }

    .ticketsDataTable td .globalButton.borderedButton {
        width: 55px;
    }

    .eventsListFilter {
        border-left-width: 5px;
    }

    .ticketsDataTable .eventsListFilterUnit select,
    .ticketsDataTable .eventsListFilterUnit input {
        width: calc(100% - 44px);
    }

    .titleSocials a.globalButton.borderedButton {
        padding: 14px 5px;
    }

    .eventsListIdentifier .dateDayFocus.inEventsList .dateDayFocusRest p,
    .eventsListIdentifier .dateDayFocus.inEventsList .dateDayFocusRest p.day {
        font-size: 10px;
    }

    .eventsListIdentifier.eventsList td.dateCell {
        width: 80px;
    }

    .eventsListIdentifier .dateDayFocus.inEventsList {
        width: 70px;
    }

    .eventsListIdentifier .globalButtonsBar {
        margin-top: 10px;
    }

    .eventsListIdentifier .globalButtonsBar a.globalButton {
        float: left;
    }

    .eventsListIdentifier .dateDayFocus.inEventsList {
        border-right: 0;
    }

    .eventsListIdentifier.eventsList td {
        padding: 10px 0 !important;
    }

    .dateDayFocus.inEventsList {
        width: 60px;
    }

    .eventsList td.dateCell.mini {
        width: 80px;
    }

    .accountRowLabel {
        width: 65px;
    }

    .eventsList td.dateCell {
        width: 80px;
    }

    p.accountError {
        margin-top: 5px;
    }

    .accountRowLabel {
        width: 75px;
    }

    .overlay .globalPopupCore .contentsCore .tableLike .rowLike .cellLike {
        padding: 16px 5px;
    }

    .errorVerifyPage {
        margin-right: 50px;
    }

    .iti-mobile .intl-tel-input.iti-container {
        top: 55px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (max-width:381px) {
    .globalButton.inputSidedButton {
        margin-top: 10px;
    }

    a.cancelEdit {
        margin-top: 20px;
    }

    .globalFormUnitRadio.big img {
        /* width: 80%; */
        width: 36%;
    }

    .errorChangeNumVerifyPage {
        margin-right: 0;
    }
}

@media only screen and (max-width:360px) {
    .eventsList td.priceCell {
        width: 90px;
    }

    td.priceCell h1 {
        font-size: 13px;
    }
}

@media only screen and (max-width:360px) {
    .contents {
        padding: 30px 0;
    }

    .contentsTitle h1,
    .contentsTitle h2 {
        margin-bottom: 5px;
    }

    .accountRowValueData {
        width: 145px;
    }
}

@media only screen and (max-width:378px) {
    .addMarginTopOnresponsive {
        margin-top: 10px;
    }

    .featuredEventsCarousel.noResults img {
        width: 80%;
    }
}

@media only screen and (max-width:350px) {
    .globalButtonsBar.extraWidth .globalButton.borderedButton {
        width: 136px;
    }
}

@media only screen and (max-width: 320px) {
    .paymentMethods .globalFormUnitRadio .globalFormUnitCheckCore {
        width: 30px;
        height: 30px;
    }

    .featuredEventsCarousel.noResults img {
        width: 80%;
    }

    .unitWithImageData p.date {
        font-size: 12px;
    }

    .unitWithImageData svg {
        width: 17px;
    }

    .accountRowValueData {
        overflow: hidden;
        text-overflow: ellipsis;
    }
};